import { useState, useCallback, useMemo } from 'react'
import classNames from 'classnames'

import { useGoalProvider } from '@edwin/react-sdk'

import { AButton } from '../atoms/AButton'

export const MChatNumericalRange = ({ text, min = 1, max = 5, step = 1, ratingInstructions }) => {
  const [selectedOption, setSelectedOption] = useState(null)

  const options = useMemo(() => {
    return Array.from({ length: Math.floor((max - min) / step) + 1 }, (_, i) => min + i * step)
  }, [max, min, step])

  const { onSendHiddenMsg = () => {} } = useGoalProvider()

  const handleChooseOption = useCallback(
    option => {
      onSendHiddenMsg(option)
      setSelectedOption(option)
    },
    [onSendHiddenMsg]
  )

  return (
    <div className="py-3 px-3.5">
      <h4 className="text-lg font-p22">{text}</h4>
      <div className="flex flex-wrap justify-between gap-1.5 mt-4">
        {options.map(option => (
          <AButton
            key={option}
            onClick={() => {
              handleChooseOption(option)
            }}
            outline={selectedOption !== option}
            small
            disabled={!!selectedOption}
            className={classNames(selectedOption === option && '!opacity-100')}
          >
            {option}
          </AButton>
        ))}
      </div>
      {ratingInstructions && (
        <div className="flex justify-between mt-1 text-sm text-gray-600">
          <p>Worst</p>
          <p>Best</p>
        </div>
      )}
    </div>
  )
}
