import React, { useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useUser } from '@edwin/react-sdk'

import { PieChart } from 'react-minimal-pie-chart'
import { AMarkdown } from '../atoms/AMarkdown'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

const PIE_CHART_COLOR = '#2A395A'
const TOTAL_VALUE_COLOR = '#7C91CF'

export const MChatInfographicPiechart = ({
  data,
  totalValue = 100,
  label,
  descriptionMarkdown,
}) => {
  const { user } = useUser()

  const pieChartValue = useMemo(() => parseInt(data[0]?.value), [data])

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex items-center pt-5 px-3.5 lg:py-6">
        <div className="w-14">
          <PieChart
            data={[
              { color: TOTAL_VALUE_COLOR, value: totalValue - pieChartValue },
              { color: PIE_CHART_COLOR, value: pieChartValue },
            ]}
            totalValue={totalValue}
            lineWidth={36}
            animate
            animationDuration={1200}
          />
        </div>
        <div className="ml-3 text-4xl text-green-dark font-extrabold">{pieChartValue}</div>
      </div>
      <div className="hidden lg:block py-4 px-2">
        <div className="w-px h-full bg-alto" />
      </div>
      <div className="py-4 px-3.5 lg:self-center">
        <AMarkdown>{_.template(descriptionMarkdown)({ user })}</AMarkdown>
      </div>
    </div>
  )
}

MChatInfographicPiechart.propTypes = {
  label: PropTypes.string.isRequired,
  descriptionMarkdown: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      // color: PropTypes.string,
    })
  ),
  totalValue: PropTypes.number,
}
