import { useMemo } from 'react'
import { query, where, collection } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import {
  createInsightsAssistantThreadFBFunction,
  auth,
  firestoreDb,
  COLLECTIONS,
  createAssistantThreadWithInputFBFunction,
  markAssistantComponentThreadAsCompletedFBFunction,
  createCheckinAssistantThreadOnRequestFBFunction,
} from '@services/Firebase'
import { DateTime } from 'luxon'

export const createInsightsAssistantThread = async prompt => {
  if (!prompt) {
    throw new Error('No prompt provided')
  }

  const response = await createInsightsAssistantThreadFBFunction({ prompt })

  const thread = response.data

  if (!thread?.id) {
    throw new Error('No insight thread created')
  }

  return thread
}

export const createAssistantThreadWithInput = async ({ assistantId, type, input }) => {
  if (!type) {
    throw new Error('No type provided')
  }

  const response = await createAssistantThreadWithInputFBFunction({ assistantId, type, input })

  const thread = response.data

  if (!thread?.id) {
    throw new Error('No thread created')
  }

  return thread
}

export const createCheckinAssistantThreadOnRequest = async ({ input }) => {
  const response = await createCheckinAssistantThreadOnRequestFBFunction({ input })

  const thread = response.data

  if (!thread?.id) {
    throw new Error('No thread created')
  }

  return thread
}

export const markAssistantComponentThreadAsCompleted = async ({ threadId }) => {
  if (!threadId) {
    throw new Error('No threadId provided')
  }

  await markAssistantComponentThreadAsCompletedFBFunction({ threadId })
}

export const useMostRecentInsight = () => {
  const [insightsRaw, isInsightsLoading, isError] = useCollectionData(
    query(
      collection(firestoreDb, COLLECTIONS.assistantThreads),
      where('userId', '==', auth.currentUser.uid),
      where('type', '==', 'insights'),
      where('isThreadInitiated', '==', false)
    )
  )

  const insights = useMemo(() => {
    if (!insightsRaw?.length) {
      return
    }

    return insightsRaw
      .filter(insight => !insight.seen)
      .sort((a, b) => b.createdAt - a.createdAt)
      .map(insight => {
        return {
          ...insight,
          id: insight.id,
          createdAt: DateTime.fromMillis(insight.createdAt * 1000).toJSDate(),
        }
      })
  }, [insightsRaw])

  const mostRecentInsight = useMemo(() => {
    if (!insights?.length) return

    // Check if insight is from this week
    const insight = insights.find(ins => {
      const weekStart = DateTime.now().startOf('week')
      const insightCreatedAt = DateTime.fromJSDate(ins.createdAt)
      return insightCreatedAt >= weekStart
    })

    return insight
  }, [insights])

  return [mostRecentInsight, isInsightsLoading, isError]
}
